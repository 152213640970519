.questionMarkDown img {
  max-width: 100%;
  margin: 0 auto;
  width: var(--maxImageWidth);
  border-radius: 6px;
}

.questionMarkDown img:first-of-type {
  margin-top: 10px;
}
