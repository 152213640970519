.bouncingLoader {
  display: flex;
  justify-content: center;
}

.bouncingLoaderItem {
  width: 8px;
  height: 8px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncingLoaderAnimation 0.6s infinite alternate;
}

@keyframes bouncingLoaderAnimation {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncingLoaderItem2 {
  animation-delay: 0.2s;
}

.bouncingLoaderItem3 {
  animation-delay: 0.4s;
}
