.sendButton {
  top: auto;
}

.textarea {
  overflow-y: auto;
}

.textarea:focus-visible {
  border-color: #b1c3ff !important;
  box-shadow: 0 0 0 1px #b1c3ff !important;
}

.textarea::-webkit-scrollbar {
  width: 6px;
}

.textarea::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-track {
  background: transparent;
}

.textarea::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.textarea::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.6);
}

.fileWrapper {
  overflow: hidden;
  width: 80px;
  height: 80px;
  border-radius: 6px;
  border: 1px solid #0000001f;
  box-shadow: 0 2px 5px 2px #00000012;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.fileWrapperImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
