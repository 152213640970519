.wrapper a {
  color: var(--color, #ff6a61);
  text-decoration: underline;
}
.wrapper * {
  font-family: var(--font-family) !important;
}

.header {
  padding: 16px 24px;
  background: var(--color, #ff6a61);
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

.footer {
  width: max-content;
  margin: 0 auto;
  text-decoration: none !important;
}
.footer__content {
  padding: 14px;
  padding-top: 0;
  margin-top: -2px;
  font-size: 10px;
  font-weight: bold;
  color: #888d8f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer img {
  vertical-align: bottom;
  height: 24px;
}

.content {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.6);
}

/* .logoContainer {
  logoContainer
} */

.retry {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.retry g {
  fill: #f56565;
}

.reactMarkDown {
  font-size: 14px;
  line-height: 1.8;
}

.reactMarkDown ul {
  list-style: disc outside none;
}

.reactMarkDown ul li,
ol li {
  margin-left: 1em;
  display: list-item;
  text-align: -webkit-match-parent;
}

.reactMarkDown ol {
  list-style: decimal outside none;
}

.reactMarkDown ol li,
.reactMarkDown ol li {
  margin-left: 2em;
  display: list-item;
  text-align: -webkit-match-parent;
}

.reactMarkDown h1:not(:last-child),
.reactMarkDown h2:not(:last-child),
.reactMarkDown h3:not(:last-child),
.reactMarkDown h4:not(:last-child),
.reactMarkDown h5:not(:last-child),
.reactMarkDown h6:not(:last-child),
.reactMarkDown p:not(:last-child),
.reactMarkDown ul:not(:last-child),
.reactMarkDown ol:not(:last-child) {
  margin-bottom: 1rem;
}

.reactMarkDown table {
  background: #6d81ff21;
  font-size: 12px;
  line-height: 1.3;
}
.reactMarkDown th {
  background: #6d81ff21;
}
.reactMarkDown td {
  background: #f0f3ff;
}
.reactMarkDown th,
.reactMarkDown td {
  padding: 6px 8px;
  border: 1px solid #c7cce154;
}
.reactMarkDown img {
  max-width: 100%;
  width: var(--maxImageWidth);
}

.message h1,
.message h2,
.message h3,
.message h4,
.message h5,
.message h6,
.message ul,
.message ol,
.message p {
  margin-bottom: 0.5rem !important;
  line-height: 1.6;
}
.message h1:last-child,
.message h2:last-child,
.message h3:last-child,
.message h4:last-child,
.message h5:last-child,
.message h6:last-child,
.message p:last-child {
  margin-bottom: 0 !important;
  line-height: 1.6;
}

.message h1,
.message h2,
.message h3,
.message h4,
.message h5,
.message h6 {
  font-size: 15px;
  font-weight: bold;
}
